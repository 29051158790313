import * as React from "react";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import AnchorIcon from "@material-ui/icons/Anchor";
import ListIcon from "@material-ui/icons/List";
import CableIcon from "@material-ui/icons/Cable";
import AssessmentIcon from "@material-ui/icons/Assessment";
import DirectionsBoatIcon from "@material-ui/icons/DirectionsBoat";

import Toolbar from "@material-ui/core/Toolbar";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { useGetShip } from "../api/api";

export const MainDrawer = (props) => {
  const matchesOperations = useRouteMatch("/ships/:id/operations");
  const matchesLines = useRouteMatch("/ships/:id/lines");
  const matchesInspections = useRouteMatch("/ships/:id/inspections");
  const matchesReports = useRouteMatch("/ships/:id/reports");
  const matchesShip = useRouteMatch("/ships/:id/");

  const history = useHistory();

  const ship = useGetShip(props.shipId);

  const drawer = (
    <div>
      {ship.title ? (
        <Toolbar>
          <Typography variant="h5" component="div" noWrap sx={{ flexGrow: 1 }}>
            {ship.title}
          </Typography>
        </Toolbar>
      ) : (
        <Toolbar />
      )}
      <Divider />
      <List>
        <ListItem
          selected={matchesOperations != null}
          button
          key="Operations"
          onClick={() => {
            history.push(`/ships/${ship.id}/operations/`);
          }}
        >
          <ListItemIcon>
            <AnchorIcon />
          </ListItemIcon>
          <ListItemText primary="Operations" />
        </ListItem>

        <ListItem
          selected={matchesLines != null}
          button
          key="Mooring lines"
          onClick={() => {
            history.push(`/ships/${ship.id}/lines/`);
          }}
        >
          <ListItemIcon>
            <CableIcon />
          </ListItemIcon>
          <ListItemText primary="Mooring lines" />
        </ListItem>

        <ListItem
          selected={matchesInspections != null}
          button
          key="Inspections"
          onClick={() => {
            history.push(`/ships/${ship.id}/inspections/`);
          }}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Inspections" />
        </ListItem>

        <ListItem
          selected={matchesReports != null}
          button
          key="Reports"
          onClick={() => {
            history.push(`/ships/${ship.id}/reports/`);
          }}
        >
          <ListItemIcon>
            <AssessmentIcon />
          </ListItemIcon>
          <ListItemText primary="Reports" />
        </ListItem>

        <ListItem
          selected={matchesShip?.isExact}
          button
          key="Ship"
          onClick={() => {
            history.push(`/ships/${ship.id}/`);
          }}
        >
          <ListItemIcon>
            <DirectionsBoatIcon />
          </ListItemIcon>
          <ListItemText primary="Ship" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          key="Dashboard"
          onClick={() => {
            history.push(`/dashboard/`);
          }}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: props.drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        variant="temporary"
        open={props.mobileOpen}
        onClose={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: props.drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: props.drawerWidth,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};
