import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import { Link } from "react-router-dom";
import { useGetMooringLines } from "../api/api";
import { MainMenuToolbar } from "../components/MainMenuToolbar";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { AppBar, CardActionArea, Fab, Tab, Tabs } from "@material-ui/core";
import { useMemo } from "react";

import AddIcon from "@material-ui/icons/Add";
import SwipeableViews from "react-swipeable-views";
import { useSessionStorage } from "../helpers/useSessionStorage";
import formatStaticEquipment from "../helpers/formatStaticEquipment";

export const Lines = (props) => {
  const shipId = props.match.params.shipId;

  const { data: mooringLines } = useGetMooringLines(shipId);
  const { data: historicalMooringLines } = useGetMooringLines(shipId, true);

  const [selectedTab, setSelectedTab] = useSessionStorage(
    `Lines_selected_tab_${shipId}`,
    0
  );

  return (
    <div>
      <MainMenuToolbar title="Mooring Lines" />

      <Container maxWidth="sm" disableGutters>
        <AppBar position="static" color="default" elevation={0}>
          <Tabs
            value={selectedTab}
            onChange={(_, newValue) => {
              setSelectedTab(newValue);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab
              label="Current"
              id="full-width-tab-0"
              aria-controls="full-width-tabpanel-0"
            />
            <Tab
              label="History"
              id="full-width-tab-1"
              aria-controls="full-width-tabpanel-1"
            />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={"x"}
          index={selectedTab}
          onChangeIndex={(index) => {
            setSelectedTab(index);
          }}
        >
          <Container maxWidth="sm">
            {/* TODO: move padding to container (how?) */}
            <Grid container spacing={2} sx={{ mt: 1, pb: 5 }}>
              {mooringLines?.map((line) => {
                return (
                  <Grid key={line.id} item xs={12}>
                    <LineCard mooringLine={line} shipId={shipId} />
                  </Grid>
                );
              })}
            </Grid>
          </Container>
          <Container maxWidth="sm">
            {/* TODO: move padding to container (how?) */}
            <Grid container spacing={2} sx={{ mt: 1, pb: 5 }}>
              {historicalMooringLines?.map((line) => {
                return (
                  <Grid key={line.id} item xs={12}>
                    <LineCard mooringLine={line} shipId={shipId} />
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </SwipeableViews>
      </Container>

      <Link to={`/ships/${shipId}/lines/new/`}>
        <Fab
          color="primary"
          aria-label="add"
          sx={{ position: "fixed", zIndex: 1, bottom: 16, right: 24 }}
        >
          <AddIcon />
        </Fab>
      </Link>
    </div>
  );
};

const formatLocation = (staticEquipment) => {
  return staticEquipment ? formatStaticEquipment(staticEquipment) : "not set";
};

const LineCard = ({ mooringLine, shipId }) => {
  const remaining = useMemo(() => {
    let minutes = mooringLine.remaining / 60;
    const hours = Math.floor(minutes / 60);
    minutes = minutes - hours * 60;

    return `${hours}h ${minutes.toFixed(0)}m`;
  }, [mooringLine?.remaining]);

  const color = mooringLine.remaining / 60 / 60 < 100 ? "error" : undefined;

  return (
    <Link to={`/ships/${shipId}/lines/${mooringLine.id}/`}>
      <Card>
        <CardActionArea>
          <CardContent>
            <Typography component="div">{mooringLine.tag}</Typography>
            <Typography variant="body2" color="text.secondary">
              Location: <b>{formatLocation(mooringLine.static_equipment)}</b>
            </Typography>
            <Typography
              variant="body2"
              color={color}
              sx={{
                position: "absolute",
                top: "16px",
                right: "16px",
                letterSpacing: "1.5px",
                fontSize: "10px",
              }}
            >
              {"REMAINING: " + remaining}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
};
