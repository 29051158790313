import React from "react";
import Container from "@material-ui/core/Container";

import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { useGetOperation } from "../api/api";
import { OperationTabDetails } from "../components/OperationTabDetails";
import { OperationTabEquipment } from "../components/OperationTabEquipment";
import { MainMenuToolbar } from "../components/MainMenuToolbar";
import { useSessionStorage } from "../helpers/useSessionStorage";

export const OperationDetails = (props) => {
  const shipId = props.match.params.shipId;
  const operationId = props.match.params.operationId;
  const [selectedTab, setSelectedTab] = useSessionStorage(
    `OperationDetails_selected_tab_${shipId}`,
    0
  );

  const { data: operation = {} } = useGetOperation(shipId, operationId);

  return (
    <Box bgcolor="background.paper">
      <MainMenuToolbar
        title={[operation.country, operation.port, operation.terminal]
          .filter((x) => x)
          .join(" / ")}
        backLink={`/ships/${shipId}/operations`}
      />
      <Container maxWidth="sm" disableGutters>
        <AppBar position="static" color="default" elevation={0}>
          <Tabs
            value={selectedTab}
            onChange={(_, newValue) => {
              setSelectedTab(newValue);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab
              label="Details"
              id="full-width-tab-0"
              aria-controls="full-width-tabpanel-0"
            />
            <Tab
              label="AFT"
              id="full-width-tab-1"
              aria-controls="full-width-tabpanel-1"
            />
            <Tab
              label="FWD"
              id="full-width-tab-2"
              aria-controls="full-width-tabpanel-2"
            />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={"x"}
          index={selectedTab}
          onChangeIndex={(index) => {
            setSelectedTab(index);
          }}
        >
          <OperationTabDetails operation={operation} shipId={shipId} />
          <OperationTabEquipment
            operation={operation}
            index={1}
            station="AFT"
          />
          <OperationTabEquipment
            operation={operation}
            index={2}
            station="FWD"
          />
        </SwipeableViews>
      </Container>
    </Box>
  );
};
