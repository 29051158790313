import * as React from "react";
import Typography from "@material-ui/core/Typography";

export const LabeledText = ({ label, children }) => {
  return (
    <div>
      <Typography
        variant="subtitle2"
        color="text.secondary"
        sx={{
          fontWeight: 400,
          mt: 1,
        }}
      >
        {label}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mb: 1,
        }}
      >
        {children}
      </Typography>
    </div>
  );
};
