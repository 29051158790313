import React, { useMemo, useState } from "react";

import { useHistory } from "react-router-dom";
import { useAddCable, useGetEquipment, useGetOperation } from "../api/api";
import { OperationLinePartial } from "./OperationLinePartial";

const directionsFwd = ["Head", "Breast", "Spring"];
const directionsAft = ["Stern", "Breast", "Spring"];

export const OperationLineNew = ({ location, match }) => {
  const params = new URLSearchParams(location.search);
  const history = useHistory();
  const station = params.get("station");
  const { shipId, operationId } = match.params;

  const directions = station === "FWD" ? directionsFwd : directionsAft;

  const { data: equipment } = useGetEquipment(shipId);
  const { data: operation } = useGetOperation(shipId, operationId);
  const addCable = useAddCable(shipId, operationId, {
    onSuccess: () => {
      history.goBack();
    },
  });

  const winches = useMemo(() => {
    if (!equipment || !operation) return [];

    const winches = equipment.filter((x) => x.direction === station);

    const usedCableIds = operation.equipment.map((e) => e.cable.id);

    const winchesWithFilteredCables = winches.map((w) => {
      w.cables = w.cables.filter((c) => {
        return !usedCableIds.includes(c.id);
      });
      return w;
    });

    return winchesWithFilteredCables;
  }, [equipment, operation, station]);

  const [selectedCable, setSelectedCable] = useState();
  const [selectedWinch, setSelectedWinch] = useState();
  const [selectedDirection, setSelectedDirection] = useState();

  const onWinchChange = (event) => {
    const winch = winches.find((x) => x.id === event.target.value);
    setSelectedWinch(winch);
    setSelectedCable();
    setSelectedDirection();
  };

  const onCableChange = (event) => {
    const cable = selectedWinch.cables.find((x) => x.id === event.target.value);
    setSelectedCable(cable);
    setSelectedDirection();
  };

  const onDirectionChange = (event) => {
    setSelectedDirection(event.target.value);
  };

  return (
    <OperationLinePartial
      mode="create"
      shipId={shipId}
      operationId={operationId}
      selectedWinch={selectedWinch}
      onWinchChange={onWinchChange}
      winches={winches}
      selectedCable={selectedCable}
      onCableChange={onCableChange}
      selectedDirection={selectedDirection}
      onDirectionChange={onDirectionChange}
      directions={directions}
      onPrimaryButtonClick={() => {
        addCable.mutate({
          arrangement: selectedDirection,
          cable_id: selectedCable.id,
        });
      }}
    />
  );
};
