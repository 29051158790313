const formatSeconds = (seconds, addPlusForPositive = false) => {
  // Must work with positive numbers, otherwise Math.floor breaks
  const localSeconds = seconds > 0 ? seconds : -seconds;
  const sign = seconds > 0 ? (addPlusForPositive ? "+" : "") : "-";

  let minutes = Math.floor(localSeconds / 60);
  const hours = Math.floor(minutes / 60);
  minutes = minutes - hours * 60;

  return `${sign}${hours}h ${minutes.toFixed(0)}m`;
};

export default formatSeconds;
