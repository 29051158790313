import Container from "@material-ui/core/Container";
import { useGetMooringLine, useGetShip } from "../../api/api";
import { MainMenuToolbar } from "../../components/MainMenuToolbar";
import Typography from "@material-ui/core/Typography";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import { Box } from "@material-ui/system";
import { useMemo } from "react";
import SwipeableViews from "react-swipeable-views";
import { CableOperationsTab } from "./CableOperationsTab";
import formatSeconds from "../../helpers/formatSeconds";
import { DetailsTab } from "./DetailsTab";
import { useSessionStorage } from "../../helpers/useSessionStorage";
import { Warning } from "./Warning";
import formatDate from "../../helpers/formatDate";

const getWarnings = (cable, ship) => {
  if (!cable || cable.deleted_at || !ship) {
    return [];
  }

  const showWarning = cable.remaining ? cable.remaining < 3600 * 40 : false;
  const warnings = [
    showWarning ? "Replace in less than 40h!" : undefined,
    cable.LDBF < ship.ldbf.min || cable.LDBF > ship.ldbf.max
      ? `Line LDBF must be between ${ship.ldbf.min} and ${ship.ldbf.max} tons. Currently ${cable.LDBF} tons`
      : undefined,
    cable.wear >= 25
      ? `Not fit for service due to excessive wear (${cable.wear}%)`
      : undefined,
  ].filter(Boolean);

  return warnings;
};

export const LineDetails = (props) => {
  const { shipId, lineId: cableId } = props.match.params;

  const { data: cable } = useGetMooringLine(shipId, cableId);

  const remaining = useMemo(() => {
    if (!cable) return;

    return formatSeconds(cable.remaining);
  }, [cable]);

  const [selectedTab, setSelectedTab] = useSessionStorage(
    `LineDetails_selected_tab_${cableId}`,
    0
  );

  const ship = useGetShip(shipId);

  const warnings = getWarnings(cable, ship);

  return (
    <div>
      <MainMenuToolbar
        title={`Line #${cable?.tag}`}
        backLink={shipId && `/ships/${shipId}/lines/`}
      />
      <Container maxWidth="sm">
        {warnings.map((warning) => (
          <Warning warning={warning} />
        ))}
        {cable?.deleted_at ? (
          <Warning
            warning={`Disposed at ${formatDate(cable.deleted_at)}`}
            style="deleted"
          />
        ) : (
          <div />
        )}
        {cable && (
          <Box>
            <Box mx={{ textAlign: "center", marginBottom: 8, marginTop: 24 }}>
              <Typography variant="h4">{`Remaining: ${remaining}`}</Typography>
            </Box>
          </Box>
        )}
        <AppBar position="static" color="default" elevation={1}>
          <Tabs
            value={selectedTab}
            onChange={(_, newValue) => {
              setSelectedTab(newValue);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab
              label="Details"
              id="full-width-tab-0"
              aria-controls="full-width-tabpanel-0"
            />
            <Tab
              label="Operations"
              id="full-width-tab-1"
              aria-controls="full-width-tabpanel-1"
            />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={"x"}
          index={selectedTab}
          onChangeIndex={(index) => {
            setSelectedTab(index);
          }}
        >
          <div>{cable && <DetailsTab shipId={shipId} cable={cable} />}</div>

          <div>
            {cable && <CableOperationsTab shipId={shipId} cable={cable} />}
          </div>
        </SwipeableViews>
      </Container>
    </div>
  );
};
