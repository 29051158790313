import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Operations } from "./views/Operations";
import { OperationDetails } from "./views/OperationDetails";
import { EditMooring, NewMooring } from "./views/NewMooring";
import { OperationLineNew } from "./views/OperationLineNew";
import MainMenu from "./components/MainMenu";
import HomeMenu from "./components/HomeMenu";
import { Lines } from "./views/Lines";
import { LineDetails } from "./views/LineDetails";
import { LineDetailsEdit } from "./views/LineDetailsEdit";
import { LineDetailsNew } from "./views/LineDetailsNew";
import { Inspections } from "./views/Inspections";
import { InspectionEdit, InspectionNew } from "./views/InspectionEdit";
import { LineOperationNew } from "./views/LineOperationNew";
import { Login } from "./views/Login";
import { useEffect } from "react";
import { useGetShips, useLogout } from "./api/api";
import { useHistory } from "react-router";
import { useSessionStorage } from "./helpers/useSessionStorage";
import { useQueryClient } from "react-query";
import { Reports } from "./views/Reports";
import { Ship } from "./views/Ship";
import { Dashboard } from "./views/Dashboard";

const ShipSwitcher = ({ children }) => {
  const [token] = useSessionStorage("api_token");
  const history = useHistory();

  useEffect(() => {
    if (!token) {
      // not logged in so redirect to login page with the return url
      history.replace(`/login`);
    } else {
      // has a new token so redirect to root and let it take over
      history.replace(`/`);
    }
  });

  return <div>{children}</div>;
};

const Root = () => {
  const ships = useGetShips();
  const history = useHistory();

  useEffect(() => {
    if (ships.data) {
      if (ships.data.length == 1) {
        history.replace(`/ships/${ships.data[0].id}/operations`);
      } else {
        history.replace(`/dashboard`);
      }
    }
  }, [ships.data]);

  return (
    <div>
      <p>loading ...</p>
    </div>
  );
};

const Navigation = () => {
  const [token, setToken] = useSessionStorage("api_token");

  const logout = useLogout({
    onSuccess: () => {
      setToken();
    },
    onError: () => {
      setToken();
    },
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries();
    queryClient.resetQueries();
  }, [token]);

  return (
    <BrowserRouter>
      <ShipSwitcher>
        <Switch>
          <Route path="/login" exact>
            <Login setToken={setToken} />
          </Route>
          <Route exact path="/" component={Root} />

          <Route
            exact
            path="/dashboard"
            component={() => {
              return (
                <HomeMenu
                  logout={() => {
                    logout.mutate();
                  }}
                >
                  <Dashboard />
                </HomeMenu>
              );
            }}
          />

          <Route
            path="/ships/:shipId/"
            render={(props) => {
              if (!token) {
                // not logged in so redirect to login page with the return url
                return (
                  <Redirect
                    to={{
                      pathname: "/login",
                      state: { from: props.location },
                    }}
                  />
                );
              }

              // authorized so return component
              return (
                <MainMenu
                  logout={() => {
                    logout.mutate();
                  }}
                  shipId={props.match.params.shipId}
                >
                  <Route
                    path="/ships/:shipId/operations/"
                    component={Operations}
                    exact
                  />
                  <Route
                    path="/ships/:shipId/operations/:operationId"
                    component={OperationDetails}
                    exact
                  />
                  <Route
                    path="/ships/:shipId/operations/:operationId/new-line"
                    component={OperationLineNew}
                  />
                  <Route
                    path="/ships/:shipId/new-mooring/"
                    component={NewMooring}
                    exact
                  />
                  <Route
                    path="/ships/:shipId/operations/:operationId/edit"
                    component={EditMooring}
                    exact
                  />
                  <Route path="/ships/:shipId/lines/" component={Lines} exact />
                  <Route
                    exact
                    path="/ships/:shipId/lines/new/"
                    component={LineDetailsNew}
                  />
                  <Route
                    exact
                    path="/ships/:shipId/lines/:lineId(\d+)"
                    component={LineDetails}
                  />
                  <Route
                    path="/ships/:shipId/lines/:lineId/edit/"
                    component={LineDetailsEdit}
                    exact
                  />
                  <Route
                    path="/ships/:shipId/lines/:lineId/add-operation/"
                    component={LineOperationNew}
                    exact
                  />
                  <Route
                    path="/ships/:shipId/inspections"
                    component={Inspections}
                    exact
                  />
                  <Route
                    path="/ships/:shipId/inspections/new"
                    component={InspectionNew}
                    exact
                  />
                  <Route
                    path="/ships/:shipId/inspections/:inspectionId/edit"
                    component={InspectionEdit}
                    exact
                  />
                  <Route
                    path="/ships/:shipId/reports"
                    component={Reports}
                    exact
                  />
                  <Route path="/ships/:shipId" component={Ship} exact />
                </MainMenu>
              );
            }}
          />
        </Switch>
      </ShipSwitcher>
    </BrowserRouter>
  );
};

export default Navigation;
