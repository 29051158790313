import { Divider, MenuItem } from "@material-ui/core";
import * as React from "react";
import { useGetEquipment } from "../../api/api";

export const getLineStartingPoint = (cable) => {
  return `${cable.winch.position_on_deck.x},${cable.winch.position_on_deck.y}`;
};

export const useGetGroupedEquipment = (shipId, location) => {
  const { data: shipsEquipment = [] } = useGetEquipment(shipId);

  const equipmentForLocation = shipsEquipment.filter(
    (x) => x.direction === location
  );

  const grouped = equipmentForLocation.reduce((acc, value) => {
    const key = `${value.position_on_deck.x},${value.position_on_deck.y}`;
    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(value);

    return acc;
  }, {});

  return Object.values(grouped);
};

export function capitalize(str) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return str;
  }
}

export const MenuItemsArrangements = ({
  arrangements,
  onArrangementSelect,
}) => {
  return (
    <div>
      <MenuItem disabled dense>
        Arrangement
      </MenuItem>
      {arrangements.map((arrangement) => {
        return (
          <MenuItem onClick={() => onArrangementSelect(arrangement)}>
            {arrangement}
          </MenuItem>
        );
      })}
    </div>
  );
};

export const MenuItemsCables = ({
  stations,
  disabledCableIds,
  onCableSelect,
}) => {
  return stations.map((station) => {
    return (
      <div>
        <MenuItem disabled dense>
          {capitalize(station?.type)} @ {station?.station_name}
        </MenuItem>
        {station?.cables?.map((cable) => {
          const disabled = disabledCableIds.includes(cable.id);
          return (
            <MenuItem
              disabled={disabled}
              onClick={() => {
                onCableSelect(cable);
              }}
            >
              {`${cable.tag} ${disabled ? "(in use)" : ""}`}
            </MenuItem>
          );
        })}
        <Divider />
      </div>
    );
  });
};

export const Circle = ({ x, y, onClick }) => {
  return (
    <g pointer-events="visible">
      <circle
        className="station"
        cx={x}
        cy={y}
        r="3"
        fill="gray"
        onClick={onClick}
      />
      <path
        fill="none"
        stroke="#CCCCCC"
        strokeWidth="0.5"
        d={`M${x - 2},${y} l4,0`}
      />
      <path
        fill="none"
        stroke="#CCCCCC"
        strokeWidth="0.5"
        d={`M${x},${y - 2} l0,4`}
      />
    </g>
  );
};
