import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import {
  Grid,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/core";
import { TabPanel } from "./TabPanel";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { Box } from "@material-ui/system";
import { useRemoveCable, useUpdateOperationSide } from "../api/api";
import DeckPlan from "./Winches/DeckPlan";
import { useHistory } from "react-router";
import formatStaticEquipment from "../helpers/formatStaticEquipment";
import { fwdConfigAigran } from "./Winches/fwdConfigAigran";
import { aftConfigAigran } from "./Winches/aftConfigAigran";

export const OperationTabEquipment = ({
  operation,
  station,
  index,
  hidden,
}) => {
  const history = useHistory();
  const shipId = operation.ship_id;

  const equipment =
    operation?.equipment?.filter((x) => x?.winch?.direction === station) || [];

  // It used to be that operation cannot be edited after it's completed (end date is entered)
  // In real life captains do this after the mooring and fill in all dates and only then try entering lines
  const canEdit = true; //!operation.completed;

  const deleteCable = useRemoveCable(shipId, operation.id);

  const [side, setSide] = useState("");

  useEffect(() => {
    if (!side || side == "") {
      setSide(operation.side);
    }
  }, [equipment]);

  const query = useUpdateOperationSide(shipId, operation.id, {});

  return (
    <TabPanel hidden={hidden} index={index} dir={"x"}>
      {canEdit ? (
        <Button
          onClick={() => {
            history.push(
              `/ships/${shipId}/operations/${operation.id}/new-line/?station=${station}`
            );
          }}
          variant="contained"
          fullWidth
        >
          Add new
        </Button>
      ) : (
        <div />
      )}

      <ToggleButtonGroup
        sx={{ mt: 2 }}
        color="primary"
        value={side}
        exclusive
        onChange={(e) => {
          const side = e.target.value;
          setSide(side);

          query.mutate({ side });
        }}
      >
        <ToggleButton value="PORT">Portside</ToggleButton>
        <ToggleButton value="STB">Starboard</ToggleButton>
      </ToggleButtonGroup>

      <Grid container spacing={2} sx={{ mt: 1, pb: 5 }}>
        {equipment.map((e) => {
          const color = (() => {
            if (e.arrangement === "Spring") {
              return "#9E2D46";
            } else if (e.arrangement === "Head" || e.arrangement === "Stern") {
              return "#469E2D";
            } else if (e.arrangement === "Breast") {
              return "#2D469E";
            }
          })();

          const card = (
            <Paper
              sx={{
                padding: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
              elevation={3}
            >
              <Typography component="span" variant="subtitle2" sx={{ mr: 1 }}>
                {formatStaticEquipment(e.winch)} / {e.cable.tag}
              </Typography>
              <Chip
                size="small"
                label={e.arrangement}
                component="span"
                style={{ backgroundColor: color, color: "white" }}
              />
              <div style={{ flex: 1 }} />
              <Button
                sx={{ marginLeft: "auto" }}
                size="small"
                color="error"
                onClick={() => {
                  if (
                    window.confirm("Are you sure you wish to delete this line?")
                  ) {
                    deleteCable.mutate(e.id);
                  }
                }}
                variant="contained"
              >
                Delete
              </Button>
            </Paper>
          );

          return (
            <Grid item xs={12} key={"Equipment" + e.id}>
              {card}
            </Grid>
          );
        })}
      </Grid>

      <Box>
        <div style={{ position: "relative" }}>
          <DeckPlan
            config={station === "AFT" ? aftConfigAigran : fwdConfigAigran}
            canEdit={canEdit}
            equipment={equipment}
            shipId={shipId}
            operationId={operation.id}
            side={side}
          />
        </div>
      </Box>
    </TabPanel>
  );
};
