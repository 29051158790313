import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Button, CardActionArea, Box } from "@material-ui/core";
import formatDate from "../../helpers/formatDate";
import formatSeconds from "../../helpers/formatSeconds";
import {
  useGetMooringLineOperationHistory,
  useGetMaintenances,
} from "../../api/api";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Fragment, useMemo } from "react";
import dayjs from "dayjs";
import formatStaticEquipment from "../../helpers/formatStaticEquipment";

export const CableOperationsTab = ({ shipId, cable }) => {
  const cableId = cable.id;
  const { data: { operations: mooringOperations } = { operations: [] } } =
    useGetMooringLineOperationHistory(shipId, cableId);

  const { data: maintenanceOperations = [] } = useGetMaintenances(
    shipId,
    cableId
  );

  const allOperations = useMemo(() => {
    const tmpMooringOperations = mooringOperations.map((x) => {
      return {
        ...x,
        date: dayjs(x.first_line),
        type: "MOORING",
      };
    });

    const tmpMaintenanceOperations = maintenanceOperations.map((x) => {
      return {
        ...x,
        date: dayjs(x.date),
        type: x.type,
      };
    });

    const all = tmpMooringOperations
      .concat(tmpMaintenanceOperations)
      .sort((lhs, rhs) => {
        return lhs.date.isAfter(rhs.date) ? -1 : 1;
      });
    return all;
  }, [mooringOperations, maintenanceOperations]);

  const history = useHistory();

  return (
    <Grid container spacing={2} sx={{ mt: 1, pb: 5 }}>
      {!cable.deleted_at ? (
        <Grid item xs={6}>
          <Button
            onClick={() => {
              history.push(`/ships/${shipId}/lines/${cableId}/add-operation/`);
            }}
            variant="contained"
          >
            Add Maintenance Operation
          </Button>
        </Grid>
      ) : (
        <div />
      )}
      {allOperations.map((operation) => {
        if (operation.type === "MOORING") {
          return (
            <Grid item xs={12} key={`operation-mooring-${operation.id}`}>
              <CableOperation
                type="Mooring"
                operation={operation}
                change={-operation.duration}
                shipId={shipId}
                operationId={operation.id}
              />
            </Grid>
          );
        } else {
          return (
            <Grid item xs={12} key={`operation-${operation.id}`}>
              <MaintenanceOperation {...operation} />
            </Grid>
          );
        }
      })}
    </Grid>
  );
};

const MaintenanceChange = ({ title, change, units = "", useBold = false }) => {
  if (change) {
    const formattedChange = Number.isInteger(change)
      ? change
      : change?.toFixed(1);

    const changeWithSign =
      change > 0 ? `+${formattedChange}` : `${formattedChange}`;

    const fullText = `${title}: ${changeWithSign}${units}`;
    return (
      <Typography
        variant="body2"
        color={change < 0 ? "error.main" : "success.main"}
      >
        {useBold ? <b>{fullText}</b> : fullText}
      </Typography>
    );
  } else {
    return <div />;
  }
};

const MaintenanceOperation = ({
  type,
  person,
  date,
  notes,
  old_static_equipment,
  new_static_equipment,
  old_remaining_lifespan,
  new_remaining_lifespan,
  old_length,
  new_length,
  old_diameter,
  new_diameter,
  old_LDBF,
  new_LDBF,
}) => {
  const lifespanChange = new_remaining_lifespan - old_remaining_lifespan;
  const lengthChange = new_length - old_length;
  const diameterChange = new_diameter - old_diameter;
  const LDBFChange = new_LDBF - old_LDBF;
  const locationChange =
    old_static_equipment?.id !== new_static_equipment?.id ? (
      <div>
        <Typography variant="body2" component="span">
          Location: from
        </Typography>{" "}
        <Typography variant="body2" component="span" color="error.main">
          {old_static_equipment
            ? formatStaticEquipment(old_static_equipment)
            : "not set"}
        </Typography>{" "}
        <Typography variant="body2" component="span">
          to
        </Typography>{" "}
        <Typography variant="body2" component="span" color="success.main">
          {new_static_equipment
            ? formatStaticEquipment(new_static_equipment)
            : "not set"}
        </Typography>
      </div>
    ) : (
      <div />
    );

  return (
    <Card>
      <CardActionArea>
        <CardContent>
          <Typography component="div">{`${type} (${person})`}</Typography>
          {(lifespanChange && (
            <Typography
              variant="body2"
              color={lifespanChange < 0 ? "error.main" : "success.main"}
            >
              <b>Lifespan: {formatSeconds(lifespanChange, true)}</b>
            </Typography>
          )) || <div />}
          <MaintenanceChange title="Length" change={lengthChange} units="m" />
          <MaintenanceChange
            title="Diameter"
            change={diameterChange}
            units="mm"
          />
          <MaintenanceChange title="LDBF" change={LDBFChange} units=" tons" />
          {locationChange}
          <Typography
            variant="body2"
            sx={{
              position: "absolute",
              top: "16px",
              right: "16px",
              letterSpacing: "1.5px",
              fontSize: "10px",
            }}
          >
            {formatDate(date)}
          </Typography>
          {notes && (
            <Box sx={{ mt: 4 }}>
              {/* https://kevinsimper.medium.com/react-newline-to-break-nl2br-a1c240ba746 */}
              {notes.split("\n").map((item, key) => {
                return (
                  <Fragment key={key}>
                    {item}
                    <br />
                  </Fragment>
                );
              })}
            </Box>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const CableOperation = ({ type, operation, change, shipId, operationId }) => {
  return (
    <Link to={`/ships/${shipId}/operations/${operationId}`}>
      <Card>
        <CardActionArea>
          <CardContent>
            <Typography component="div">{`${type} (${operation.country} / ${operation.port} / ${operation.terminal})`}</Typography>
            <Typography variant="body2"></Typography>
            <Typography
              variant="body2"
              color={change < 0 ? "error.main" : "success.main"}
            >
              <b>Lifespan: {formatSeconds(change, true)}</b>
            </Typography>
            <Typography
              variant="body2"
              sx={{
                position: "absolute",
                top: "16px",
                right: "16px",
                letterSpacing: "1.5px",
                fontSize: "10px",
              }}
            >
              {`${operation.first_line && formatDate(operation.first_line)} - ${
                operation.completed && formatDate(operation.completed)
              }`}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
};
