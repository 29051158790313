import * as React from "react";
import Card from "@material-ui/core/Card";
import Chip from "@material-ui/core/Chip";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardActionArea } from "@material-ui/core";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import formatDate from "../helpers/formatDate";

export const OperationCard = ({ operation, shipId }) => {
  const getStatus = () => {
    if (operation.completed) return "Unmoored";
    if (operation.first_line) return "Moored";
    return "Draft";
  };

  const getMooringHours = () => {
    if (!operation || !operation.first_line) return "-";

    const from = dayjs(operation.first_line);
    const till = dayjs(operation.completed || new Date());

    let minutes = till.diff(from, "minutes");
    const hours = Math.floor(minutes / 60);
    minutes = minutes - hours * 60;

    return `${hours}h ${minutes}m`;
  };

  return (
    <Link to={`/ships/${shipId}/operations/${operation.id}`}>
      <Card>
        <CardActionArea>
          <CardContent>
            <Typography component="div">
              {[operation.country, operation.port, operation.terminal]
                .filter((x) => x)
                .join(" / ")}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Status: <Chip size="small" label={getStatus()} component="span" />
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Mooring hours: <b>{getMooringHours()}</b>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Moored: <b>{formatDate(operation.first_line)}</b>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Unmoored: <b>{formatDate(operation.completed)}</b>
            </Typography>
            <Typography
              variant="body2"
              sx={{
                position: "absolute",
                top: "16px",
                right: "16px",
                letterSpacing: "1.5px",
                fontSize: "10px",
                textTransform: "uppercase",
              }}
            >
              {operation.type}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
};
