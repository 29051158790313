import Container from "@material-ui/core/Container";
import { MainMenuToolbar } from "../components/MainMenuToolbar";
import { Button, InputLabel, TextField, Typography } from "@material-ui/core";
import { Box } from "@material-ui/system";
import TextFieldWithError from "../components/TextFieldWithError";
import { DatePicker } from "@mui/lab";

export const LineDetailsPartial = ({
  title,
  backLink,
  submitButtonTitle,
  editedCable,
  error,
  setEditedCable,
  onSubmitClick,
}) => {
  const globalError = error?.message?.message;

  return (
    <div>
      <MainMenuToolbar title={title} backLink={backLink} />
      <Container maxWidth="sm">
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" sx={{ mt: 2 }}>
            Cable tag number
          </Typography>
          <TextFieldWithError
            label="Tag *"
            objectKey="tag"
            error={error}
            editedCable={editedCable}
            setEditedCable={setEditedCable}
          />

          <Typography variant="h4" sx={{ mt: 2 }}>
            Parameters
          </Typography>
          <TextField
            error={error?.message?.lifespan}
            helperText={error?.message?.lifespan}
            onKeyUp={(e) => {
              e.target.value = e.target.value.replace(/[^\d]/g, "");
            }}
            label="Lifespan (hours) *"
            margin="normal"
            value={editedCable?.lifespan ? editedCable.lifespan / 3600 : ""}
            type="custom_number"
            onChange={(event) => {
              setEditedCable({
                ...editedCable,
                lifespan: event.target.value * 3600,
              });
            }}
            variant="standard"
            sx={{ flex: "0 0 48%" }}
          />
          <TextFieldWithError
            label="Material *"
            objectKey="material_type"
            error={error}
            editedCable={editedCable}
            setEditedCable={setEditedCable}
          />
          <TextFieldWithError
            label="Length (m) *"
            type="custom_number"
            objectKey="length"
            error={error}
            editedCable={editedCable}
            setEditedCable={setEditedCable}
          />
          <TextFieldWithError
            label="Diameter (mm) *"
            type="custom_number"
            objectKey="diameter"
            error={error}
            editedCable={editedCable}
            setEditedCable={setEditedCable}
          />
          <TextFieldWithError
            label="LDBF (tons) *"
            objectKey="LDBF"
            error={error}
            editedCable={editedCable}
            setEditedCable={setEditedCable}
          />
          <TextFieldWithError
            label="Wear (%)"
            type="custom_number"
            objectKey="wear"
            error={error}
            editedCable={editedCable}
            setEditedCable={setEditedCable}
          />

          <Typography variant="h4" sx={{ mt: 2 }}>
            Historical information
          </Typography>
          <DatePicker
            label="First in use"
            value={editedCable?.first_in_use_override}
            onChange={(x) => {
              setEditedCable({
                ...editedCable,
                first_in_use_override: x,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={error?.message?.completed !== undefined}
                helperText={
                  error?.message?.completed ??
                  "Fill if the mooring line has been used prior entering Make It Fast system. Otherwise leave empty"
                }
                sx={{ flex: "0 0 48%", mt: 2 }}
                variant="standard"
              />
            )}
          />
          <TextFieldWithError
            label="Initial mooring hours (h)"
            type="custom_number"
            objectKey="initial_mooring_hours"
            error={error}
            editedCable={editedCable}
            setEditedCable={setEditedCable}
            helperText="Fill if the mooring line has been used prior entering Make It Fast system. Otherwise leave empty"
          />

          <Typography variant="h4" sx={{ mt: 2 }}>
            Certificate
          </Typography>
          <TextFieldWithError
            label="Certificate No."
            objectKey="certificate_no"
            error={error}
            editedCable={editedCable}
            setEditedCable={setEditedCable}
          />
          <Box sx={{ mt: 1 }}>
            <InputLabel shrink={true}>Certificate file</InputLabel>
            <Typography
              variant="body1"
              sx={{
                mb: 1,
              }}
            >
              {editedCable?.certificate_filename ?? ""}
            </Typography>
            <input
              type="file"
              name="file"
              onChange={(event) => {
                setEditedCable({
                  ...editedCable,
                  certificate: event.target.files[0],
                });
              }}
            />
          </Box>
        </Box>

        <Box sx={{ mt: 4 }}>
          {globalError && (
            <Typography variant="body1" color="error">
              {`${globalError}`}
            </Typography>
          )}
          <Button onClick={onSubmitClick} variant="contained" sx={{ mt: 1 }}>
            {submitButtonTitle}
          </Button>
        </Box>
      </Container>
    </div>
  );
};
