import { Card, CardContent, useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import WarningIcon from "@material-ui/icons/Warning";
import DeleteIcon from "@material-ui/icons/Delete";

export const Warning = ({ warning, style = "warning" }) => {
  const theme = useTheme();

  const backgroundColor =
    style === "warning" ? theme.palette.warning.main : "gray";

  return (
    <Card sx={{ mt: 1 }} color="white" style={{ backgroundColor }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {style === "warning" ? (
          <WarningIcon sx={{ color: "white", mr: 2 }} fontSize="large" />
        ) : (
          <DeleteIcon sx={{ color: "white", mr: 2 }} fontSize="large" />
        )}
        <Typography color="white" variant="h5">
          {warning}
        </Typography>
      </CardContent>
    </Card>
  );
};
