import { useGetMooringLine, useUpdateMooringLine } from "../api/api";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { LineDetailsPartial } from "./LineDetailsPartial";

export const LineDetailsEdit = (props) => {
  const { shipId, lineId: cableId } = props.match.params;

  const history = useHistory();

  const { data: cable } = useGetMooringLine(shipId, cableId);

  const [editedCable, setEditedCable] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    if (!editedCable) {
      setEditedCable(cable);
    }
    // eslint-disable-next-line
  }, [cable]);

  const editCable = useUpdateMooringLine(shipId, cableId, {
    onSuccess: () => {
      if (history.length > 0) {
        history.goBack();
      } else {
        history.push(`/ships/${shipId}/lines/${cable.id}/`);
      }
    },
    onError: setError,
  });

  return (
    <LineDetailsPartial
      title={`Line #${cable?.tag}`}
      backLink={cable && `/ships/${shipId}/lines/${cableId}/`}
      submitButtonTitle="Save"
      editedCable={editedCable}
      error={error}
      setEditedCable={setEditedCable}
      onSubmitClick={() => {
        editCable.mutate(editedCable);
      }}
    />
  );
};
