import React from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { MainMenuToolbar } from "../components/MainMenuToolbar";
import formatStaticEquipment from "../helpers/formatStaticEquipment";

export const OperationLinePartial = ({
  mode,
  shipId,
  operationId,
  selectedWinch,
  onWinchChange,
  winches,
  selectedCable,
  onCableChange,
  selectedDirection,
  onDirectionChange,
  directions,
  onPrimaryButtonClick,
  onDeleteButtonClick,
}) => {
  const title = mode === "create" ? "Add New Line" : "Edit line";
  const buttonName = mode === "create" ? "Add" : "Save";
  const hasDeleteButton = mode !== "create";
  return (
    <div>
      <MainMenuToolbar
        title={title}
        backLink={`/ships/${shipId}/operations/${operationId}`}
      />
      <Container maxWidth="sm">
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {/* TODO: Grid instead of flexbox */}

          <TextField
            id="input-select-winch"
            label="Winch"
            margin="normal"
            select
            value={selectedWinch?.id || ""}
            onChange={onWinchChange}
            variant="standard"
            sx={{ flex: "0 0 48%" }}
          >
            {winches.map((x) => {
              const key = formatStaticEquipment(x);
              return (
                <MenuItem key={key} value={x.id}>
                  {key}
                </MenuItem>
              );
            })}
          </TextField>

          {selectedWinch && (
            <TextField
              id="input-select-line"
              label="Line"
              margin="normal"
              select
              value={selectedCable?.id || ""}
              onChange={onCableChange}
              variant="standard"
              sx={{ flex: "0 0 48%" }}
            >
              {selectedWinch.cables.map((x) => (
                <MenuItem key={x.id} value={x.id}>
                  {x.tag}
                </MenuItem>
              ))}
            </TextField>
          )}

          {selectedWinch && selectedCable && (
            <TextField
              id="input-select-direction"
              label="Direction"
              margin="normal"
              select
              value={selectedDirection || ""}
              onChange={onDirectionChange}
              variant="standard"
              sx={{ flex: "0 0 48%" }}
            >
              {directions.map((direction) => (
                <MenuItem key={direction} value={direction}>
                  {direction}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Box>
        <Button
          disabled={!selectedWinch || !selectedCable || !selectedDirection}
          variant="contained"
          sx={{ mt: 1 }}
          onClick={onPrimaryButtonClick}
        >
          {buttonName}
        </Button>
        {hasDeleteButton && (
          <Button
            color="error"
            variant="contained"
            sx={{ mt: 1, ml: 1 }}
            onClick={onDeleteButtonClick}
          >
            Delete
          </Button>
        )}
      </Container>
    </div>
  );
};
