import { Button, Typography } from "@material-ui/core";
import { LabeledText } from "../../components/LabeledText";
import { useHistory } from "react-router";
import dayjs from "dayjs";

const formatWear = (x) => {
  if (!x || isNaN(x)) return "-";

  if (x < 5) return `${x}% (New)`;
  if (x < 10) return `${x}% (Good)`;
  if (x < 15) return `${x}% (Average)`;
  if (x < 20) return `${x}% (Poor)`;
  if (x < 24) return `${x}% (Bad)`;

  return `${x}% (Must be retired)`;
};

export const DetailsTab = ({ shipId, cable }) => {
  const history = useHistory();

  return (
    <>
      <Typography variant="h4" sx={{ mt: 2 }}>
        Parameters
      </Typography>
      <LabeledText label="Lifespan (h)">
        {(cable.lifespan / 3600).toFixed(1)}
      </LabeledText>
      <LabeledText label="Material">{cable.material_type || "-"}</LabeledText>
      <LabeledText label="Length (m)">{cable.length || "-"}</LabeledText>
      <LabeledText label="Diameter (mm)">{cable.diameter || "-"}</LabeledText>
      <LabeledText label="LDBF (tons)">{cable.LDBF || "-"}</LabeledText>
      <LabeledText label="Wear (%)">{formatWear(cable.wear)}</LabeledText>

      <Typography variant="h4" sx={{ mt: 2 }}>
        Historical information
      </Typography>
      <LabeledText label="First in use">
        {(cable.first_in_use_override &&
          dayjs(cable.first_in_use_override).format("YYYY-MM-DD")) ??
          "-"}
      </LabeledText>
      <LabeledText label="Initial mooring hours (h)">
        {cable.initial_mooring_hours ?? "-"}
      </LabeledText>

      <Typography variant="h4" sx={{ mt: 2 }}>
        Certificate
      </Typography>
      <LabeledText label="Certificate No.">
        {cable.certificate_no || "-"}
      </LabeledText>
      <LabeledText label="Certificate">
        {cable.certificate_filename ? (
          <a href={cable.certificate_url} target="_blank" rel="noreferrer">
            {cable.certificate_filename}
          </a>
        ) : (
          <p>-</p>
        )}
      </LabeledText>

      {!cable.deleted_at ? (
        <Button
          onClick={() => {
            history.push(`/ships/${shipId}/lines/${cable.id}/edit/`);
          }}
          variant="contained"
          sx={{ mt: 1 }}
        >
          Edit
        </Button>
      ) : (
        <div />
      )}
    </>
  );
};
