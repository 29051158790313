import { TextField } from "@material-ui/core";

const TextFieldWithError = ({
  label,
  type,
  objectKey,
  error,
  editedCable,
  setEditedCable,
  helperText,
}) => {
  return (
    <TextField
      error={error?.message?.[objectKey] !== undefined}
      helperText={error?.message?.[objectKey] ?? helperText}
      label={label}
      margin="normal"
      type={type}
      value={(editedCable ?? {})[objectKey] || ""}
      onChange={(event) => {
        if (type === "custom_number") {
          const isNumber = !Number.isNaN(Number.parseFloat(event.target.value));

          if (event.target.value === "" || isNumber) {
            var mutated = {
              ...editedCable,
            };
            mutated[objectKey] =
              event.target.value === ""
                ? ""
                : `${Number.parseFloat(event.target.value)}`;
            setEditedCable(mutated);
          }
        } else {
          var mutated = {
            ...editedCable,
          };
          mutated[objectKey] = event.target.value;
          setEditedCable(mutated);
        }
      }}
      variant="standard"
      sx={{ flex: "0 0 48%" }}
    />
  );
};

export default TextFieldWithError;
