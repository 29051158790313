import { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import { Box, Button, TextField } from "@material-ui/core";
import {
  useGetOperation,
  useCreateOperation,
  useUpdateOperation,
} from "../api/api";
import { useHistory } from "react-router-dom";
import { MainMenuToolbar } from "../components/MainMenuToolbar";
import TextFieldWithError from "../components/TextFieldWithError";
import { DateTimePicker } from "@mui/lab";

export const NewMooring = (props) => {
  const shipId = props.match.params.shipId;
  const history = useHistory();
  const [error, setError] = useState();

  const createOperation = useCreateOperation(shipId, {
    onSuccess: (operation) => {
      history.push(`/ships/${shipId}/operations/${operation.id}`);
    },
    onError: setError,
  });
  const [operation, setOperation] = useState({});

  return (
    <Partial
      shipId={shipId}
      operation={operation}
      setOperation={setOperation}
      error={error}
      title="Start New Mooring operation"
      backLink={`/ships/${shipId}/operations/`}
      onClick={() => {
        createOperation.mutate(operation);
      }}
    />
  );
};

export const EditMooring = (props) => {
  const { shipId, operationId } = props.match.params;
  const history = useHistory();
  const [error, setError] = useState();

  const { data: operationFromApi } = useGetOperation(shipId, operationId);

  const updateOperation = useUpdateOperation(shipId, operationId, {
    onSuccess: (operation) => {
      history.push(`/ships/${shipId}/operations/${operation.id}`);
    },
    onError: setError,
  });

  const [operation, setOperation] = useState({});

  useEffect(() => {
    if (operationFromApi && operation !== {}) {
      setOperation({
        ...operationFromApi,
        firstLine: operationFromApi.first_line,
      });
    }
  }, [operationFromApi]);

  return (
    <Partial
      shipId={shipId}
      operation={operation}
      setOperation={setOperation}
      error={error}
      title="Edit Mooring operation"
      backLink={`/ships/${shipId}/operations/${operationId}`}
      onClick={() => {
        updateOperation.mutate(operation);
      }}
    />
  );
};

const Partial = ({
  shipId,
  operation,
  setOperation,
  error,
  title,
  backLink,
  onClick,
}) => {
  return (
    <div>
      <MainMenuToolbar title={title} backLink={backLink} />
      <Container maxWidth="sm">
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <TextFieldWithError
            label="Country"
            objectKey="country"
            error={error}
            editedCable={operation}
            setEditedCable={setOperation}
          />
          <TextFieldWithError
            label="Port"
            objectKey="port"
            error={error}
            editedCable={operation}
            setEditedCable={setOperation}
          />
          <TextFieldWithError
            label="Terminal"
            objectKey="terminal"
            error={error}
            editedCable={operation}
            setEditedCable={setOperation}
          />
          <DateTimePicker
            label="First line"
            value={operation.firstLine || null}
            onChange={(x) => {
              setOperation({
                ...operation,
                firstLine: x,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={error?.message?.firstLine !== undefined}
                helperText={error?.message?.firstLine}
                sx={{ flex: "0 0 48%", mt: 2 }}
                variant="standard"
              />
            )}
          />
          {/* <DateTimePicker
            label="Moored"
            value={operation.moored || null}
            onChange={(x) => {
              setOperation({
                ...operation,
                moored: x,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={error?.message?.moored !== undefined}
                helperText={error?.message?.moored}
                sx={{ flex: "0 0 48%", mt: 2 }}
                variant="standard"
              />
            )}
          /> */}
          <DateTimePicker
            label="Unmoored"
            value={operation.completed || null}
            onChange={(x) => {
              setOperation({
                ...operation,
                completed: x,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={error?.message?.completed !== undefined}
                helperText={error?.message?.completed}
                sx={{ flex: "0 0 48%", mt: 2 }}
                variant="standard"
              />
            )}
          />
        </Box>
        <Button
          disabled={!operation.port || !operation.country}
          onClick={onClick}
          variant="contained"
          sx={{ mt: 4 }}
        >
          Add
        </Button>
      </Container>
    </div>
  );
};
