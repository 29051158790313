import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { QueryClientProvider, QueryClient } from "react-query";
import DateAdapter from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Navigation from "./Navigation";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2D469E",
      danger: "#FF0000",
    },
  },
});

const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Box className="App" style={{ minHeight: "100vh" }} bgcolor="grey.100">
          <QueryClientProvider client={queryClient}>
            <Navigation />
          </QueryClientProvider>
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
