import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import { Link } from "react-router-dom";
import { useGetInspections } from "../api/api";
import { MainMenuToolbar } from "../components/MainMenuToolbar";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardActionArea, Fab } from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import { Fragment } from "react";
import formatDate from "../helpers/formatDate";

export const Inspections = (props) => {
  const shipId = props.match.params.shipId;

  const { data: inspections } = useGetInspections(shipId);

  return (
    <div>
      <MainMenuToolbar title="Inspections" />
      <Container maxWidth="sm">
        {/* TODO: move padding to container (how?) */}
        <Grid container spacing={2} sx={{ mt: 1, pb: 5 }}>
          {inspections?.map((inspection) => {
            return (
              <Grid key={inspection.id} item xs={12}>
                <InspectionCard inspection={inspection} shipId={shipId} />
              </Grid>
            );
          })}
        </Grid>
      </Container>
      <Link to={`/ships/${shipId}/inspections/new/`}>
        <Fab
          color="primary"
          aria-label="add"
          sx={{ position: "fixed", zIndex: 1, bottom: 16, right: 24 }}
        >
          <AddIcon />
        </Fab>
      </Link>
    </div>
  );
};

const InspectionCard = ({ inspection, shipId }) => {
  return (
    <Link to={`/ships/${shipId}/inspections/${inspection.id}/edit/`}>
      <Card>
        <CardActionArea>
          <CardContent>
            <Typography component="div">{inspection.person}</Typography>
            <Typography variant="body2" color="text.secondary">
              {/* https://kevinsimper.medium.com/react-newline-to-break-nl2br-a1c240ba746 */}
              {inspection.notes.split("\n").map((item, key) => {
                return (
                  <Fragment key={key}>
                    {item}
                    <br />
                  </Fragment>
                );
              })}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                position: "absolute",
                top: "16px",
                right: "16px",
                letterSpacing: "1.5px",
                fontSize: "10px",
              }}
            >
              {formatDate(inspection.date)}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
};
