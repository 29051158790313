import { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getSessionValue } from "../helpers/useSessionStorage";

// const baseUrl = "http://127.0.0.1:3000";
const baseUrl = "https://mooring-api-low9z.ondigitalocean.app";

export const getReportUrl = (shipId) => {
  return `${baseUrl}/ships/${shipId}/report.xlsx`;
};

export const useLogin = ({ onSuccess = () => {}, onError = () => {} }) => {
  const queryClient = useQueryClient();

  const url = `${baseUrl}/users/sign_in`;

  return useMutation(
    ({ ship, password }) => {
      const options = {
        method: "POST",
        body: JSON.stringify({ name: ship, password }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      return fetch(url, options).then(async (response) => {
        if (response.status < 400) {
          return response.json();
        } else {
          const message = await response.json();

          throw {
            code: response.status,
            message,
          };
        }
      });
    },
    {
      onSuccess: (response) => {
        onSuccess(response);

        queryClient.invalidateQueries({
          refetchActive: true,
          refetchInactive: true,
        });
      },
      onError,
    }
  );
};

export const useLogout = ({ onSuccess = () => {}, onError = () => {} }) => {
  const queryClient = useQueryClient();

  const url = `${baseUrl}/users/sign_out`;

  const token = getSessionValue("api_token");

  return useMutation(
    () => {
      const options = {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      return fetch(url, options);
    },
    {
      onSuccess: (response) => {
        onSuccess(response);

        queryClient.invalidateQueries({
          refetchActive: true,
          refetchInactive: true,
        });
      },
      onError,
    }
  );
};

export const useGetShip = (shipId) => {
  const { data: ships = [] } = useGetShips();

  // Takes selected ship
  const ship = ships.find((x) => x.id == shipId);

  // Or first ship if none selected
  return ship ?? (ships.length > 0 ? ships[0] : {});
};

export const useGetShips = () => {
  return useQuery("ships", () => {
    return fetchMe(`${baseUrl}/ships`);
  });
};

export const useGetOperations = (shipId) => {
  return useQuery(["operations", shipId], () => {
    return fetchMe(`${baseUrl}/ships/${shipId}/operations`);
  });
};

export const useGetOperation = (shipId, operationId) => {
  const response = useGetOperations(shipId);
  const operations = response.data;

  const operation = useMemo(() => {
    if (!operations) return undefined;

    return operations.find((x) => x.id === parseInt(operationId));
  }, [operations, operationId]);

  return { ...response, data: operation };
};

export const useDeleteOperation = (
  shipId,
  operationId,
  { onSuccess = () => {}, onError = () => {} }
) => {
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      const options = {
        method: "DELETE",
      };

      return fetchMe(
        `${baseUrl}/ships/${shipId}/operations/${operationId}`,
        options
      );
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(["operations", shipId]);

        onSuccess(response);
      },
      onError,
    }
  );
};

export const useGetInspections = (shipId) => {
  return useQuery(["inspections", shipId], () => {
    return fetchMe(`${baseUrl}/ships/${shipId}/inspections`);
  });
};

export const useGetInspection = (shipId, inspectionId) => {
  return useQuery(["inspections", shipId, inspectionId], () => {
    return fetchMe(`${baseUrl}/ships/${shipId}/inspections/${inspectionId}`);
  });
};

export const useEditInspection = (
  shipId,
  inspectionId,
  { onSuccess = () => {}, onError = () => {} }
) => {
  return useMutateInspection(shipId, "PUT", inspectionId, {
    onSuccess,
    onError,
  });
};

export const useCreateInspection = (
  shipId,
  { onSuccess = () => {}, onError = () => {} }
) => {
  return useMutateInspection(shipId, "POST", "", { onSuccess, onError });
};

export const useMutateInspection = (
  shipId,
  method,
  inspectionId,
  { onSuccess, onError }
) => {
  const queryClient = useQueryClient();

  const url = `${baseUrl}/ships/${shipId}/inspections/${inspectionId}`;

  return useMutation(
    ({ person, notes, date }) => {
      const options = {
        method: method,
        body: JSON.stringify({ person, notes, date }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      return fetchMe(url, options);
    },
    {
      onSuccess: (response) => {
        onSuccess(response);
        queryClient.invalidateQueries("inspections");
      },
      onError,
    }
  );
};

export const useCreateMaintenance = (
  shipId,
  cableId,
  { onSuccess = () => {}, onError = () => {} }
) => {
  const queryClient = useQueryClient();

  const url = `${baseUrl}/ships/${shipId}/cables/${cableId}/maintenances`;

  return useMutation(
    ({
      type,
      person,
      date,
      notes,
      old_location,
      new_location,
      old_remaining_lifespan,
      new_remaining_lifespan,
      old_length,
      new_length,
      old_diameter,
      new_diameter,
      old_LDBF,
      new_LDBF,
    }) => {
      const options = {
        method: "POST",
        body: JSON.stringify({
          cable_id: cableId,
          type,
          person,
          date,
          notes,
          old_location,
          new_location,
          old_remaining_lifespan,
          new_remaining_lifespan,
          old_length,
          new_length,
          old_diameter,
          new_diameter,
          old_LDBF,
          new_LDBF,
        }),
      };

      return fetchMe(url, options);
    },
    {
      onSuccess: (response) => {
        onSuccess(response);
        queryClient.invalidateQueries(["cable_maintenances", cableId]);
      },
      onError,
    }
  );
};

export const useGetMaintenances = (shipId, cableId) => {
  return useQuery(["cable_maintenances", shipId, cableId], () => {
    return fetchMe(`${baseUrl}/ships/${shipId}/cables/${cableId}/maintenances`);
  });
};

export const useGetMooringLines = (shipId, historical = false) => {
  return useQuery(["cables", shipId, historical], () => {
    return fetchMe(
      `${baseUrl}/ships/${shipId}/cables${historical ? "?historic=true" : ""}`
    );
  });
};

export const useGetMooringLine = (shipId, id) => {
  return useQuery(["cable", shipId, id], () => {
    return fetchMe(`${baseUrl}/ships/${shipId}/cables/${id}`);
  });
};

export const useGetMooringLineOperationHistory = (shipId, id) => {
  return useQuery(["cable_operation_history", shipId, id], () => {
    return fetchMe(
      `${baseUrl}/ships/${shipId}/cables/${id}/historic_operations`
    );
  });
};

export const useUpdateMooringLine = (
  ship_id,
  id,
  { onSuccess = () => {}, onError = () => {} }
) => {
  const queryClient = useQueryClient();

  const url = `${baseUrl}/ships/${ship_id}/cables/${id}/`;

  return useMutation(
    ({
      tag,
      lifespan,
      material_type,
      length,
      diameter,
      LDBF,
      wear,
      first_in_use_override,
      initial_mooring_hours,
      certificate_no,
      certificate,
    }) => {
      const formData = new FormData();

      formData.append("cable[tag]", tag);
      formData.append("cable[lifespan]", lifespan);
      formData.append("cable[material_type]", material_type);
      formData.append("cable[length]", length);
      formData.append("cable[diameter]", diameter);
      formData.append("cable[LDBF]", LDBF);
      formData.append("cable[wear]", wear);
      formData.append("cable[first_in_use_override]", first_in_use_override);
      formData.append("cable[initial_mooring_hours]", initial_mooring_hours);
      if (certificate) {
        formData.append("cable[certificate]", certificate);
      }
      formData.append("cable[certificate_no]", certificate_no);

      const options = {
        method: "PUT",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      };

      return fetchMe(url, options);
    },
    {
      onSuccess: (response) => {
        onSuccess(response);
        queryClient.invalidateQueries(["cable", id]);
        queryClient.invalidateQueries("cables");
      },
      onError,
    }
  );
};

export const useCreateMooringLine = (ship_id, { onSuccess, onError } = {}) => {
  const queryClient = useQueryClient();

  const url = `${baseUrl}/ships/${ship_id}/cables/`;

  return useMutation(
    ({
      tag,
      lifespan,
      material_type,
      length,
      diameter,
      LDBF,
      wear,
      first_in_use_override,
      initial_mooring_hours,
      certificate_no,
      certificate,
    }) => {
      const formData = new FormData();

      if (tag) {
        formData.append("cable[tag]", tag);
      }
      if (lifespan) {
        formData.append("cable[lifespan]", lifespan);
      }
      if (material_type) {
        formData.append("cable[material_type]", material_type);
      }
      if (length) {
        formData.append("cable[length]", length);
      }
      if (diameter) {
        formData.append("cable[diameter]", diameter);
      }
      if (LDBF) {
        formData.append("cable[LDBF]", LDBF);
      }
      if (wear) {
        formData.append("cable[wear]", wear);
      }
      formData.append("cable[first_in_use_override]", first_in_use_override);
      formData.append("cable[initial_mooring_hours]", initial_mooring_hours);
      if (certificate) {
        formData.append("cable[certificate]", certificate);
      }
      if (certificate_no) {
        formData.append("cable[certificate_no]", certificate_no);
      }

      const options = {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      };

      return fetchMe(url, options);
    },
    {
      onSuccess: (response) => {
        onSuccess(response);
        queryClient.invalidateQueries("cables");
      },
      onError,
    }
  );
};

export const useGetEquipment = (shipId) => {
  return useQuery(
    ["equipment", shipId],
    () => {
      return fetchMe(`${baseUrl}/ships/${shipId}/static_equipments`);
    },
    {
      enabled: shipId != undefined,
    }
  );
};

export const useGetOperationEquipment = (
  shipId,
  operationId,
  operationEquipmentId
) => {
  return useQuery(
    ["operationEquipment", shipId, operationId, operationEquipmentId],
    () => {
      return fetchMe(
        `${baseUrl}/ships/${shipId}/operations/${operationId}/operation_equipments/${operationEquipmentId}`
      );
    }
  );
};

export const useCreateOperation = (
  shipId,
  { onSuccess = () => {}, onError = () => {} }
) => {
  const queryClient = useQueryClient();

  const url = `${baseUrl}/ships/${shipId}/operations`;

  return useMutation(
    ({ country, port, terminal, firstLine, moored, completed }) => {
      const options = {
        method: "POST",
        body: JSON.stringify({
          country,
          port,
          terminal,
          first_line: firstLine,
          moored,
          completed,
          type: "mooring",
        }),
      };

      return fetchMe(url, options);
    },
    {
      onSuccess: (response) => {
        onSuccess(response);
        queryClient.invalidateQueries("operations");
      },
      onError,
    }
  );
};

export const useUpdateOperation = (
  shipId,
  operationId,
  { onSuccess = () => {}, onError = () => {} }
) => {
  const queryClient = useQueryClient();

  const url = `${baseUrl}/ships/${shipId}/operations/${operationId}`;

  return useMutation(
    ({ country, port, terminal, firstLine, moored, completed }) => {
      const options = {
        method: "PUT",
        body: JSON.stringify({
          country,
          port,
          terminal,
          first_line: firstLine,
          moored,
          completed,
        }),
      };

      return fetchMe(url, options);
    },
    {
      onSuccess: (response) => {
        if (onSuccess) {
          onSuccess(response);
        }
        queryClient.invalidateQueries("operations");
      },
      onError,
    }
  );
};

export const useUpdateOperationSide = (
  shipId,
  operationId,
  { onSuccess = () => {}, onError = () => {} }
) => {
  const queryClient = useQueryClient();
  const url = `${baseUrl}/ships/${shipId}/operations/${operationId}`;
  return useMutation(
    ({ side }) => {
      const options = {
        method: "PUT",
        body: JSON.stringify({ side }),
      };
      return fetchMe(url, options);
    },
    {
      onSuccess: (response) => {
        if (onSuccess) {
          onSuccess(response);
        }
        // TODO: Be effective and replace the one operation
        queryClient.invalidateQueries("operations");
      },
      onError,
    }
  );
};

export const useAddCable = (
  shipId,
  operationId,
  { onSuccess = () => {} } = {}
) => {
  const queryClient = useQueryClient();

  const url = `${baseUrl}/ships/${shipId}/operations/${operationId}/operation_equipments/`;

  return useMutation(
    ({ cable_id, arrangement }) => {
      const options = {
        method: "POST",
        body: JSON.stringify({ cable_id, arrangement }),
      };

      return fetchMe(url, options);
    },
    {
      onSuccess: (response) => {
        onSuccess(response);
        queryClient.invalidateQueries("operations");
      },
    }
  );
};

export const useUpdateCable = (
  shipId,
  operationId,
  operationEquipmentId,
  { onSuccess } = {}
) => {
  const queryClient = useQueryClient();

  const url = `${baseUrl}/ships/${shipId}/operations/${operationId}/operation_equipments/${operationEquipmentId}`;

  return useMutation(
    ({ cable_id, arrangement }) => {
      const options = {
        method: "PUT",
        body: JSON.stringify({ cable_id, arrangement }),
      };

      return fetchMe(url, options);
    },
    {
      onSuccess: (response) => {
        if (onSuccess) {
          onSuccess(response);
        }
        queryClient.invalidateQueries("operations");
      },
    }
  );
};

export const useRemoveCable = (shipId, operationId, { onSuccess } = {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    (operationEquipmentId) => {
      const url = `${baseUrl}/ships/${shipId}/operations/${operationId}/operation_equipments/${operationEquipmentId}`;

      const options = {
        method: "DELETE",
      };

      return fetchMe(url, options);
    },
    {
      onSuccess: (response) => {
        if (onSuccess) {
          onSuccess(response);
        }
        queryClient.invalidateQueries("operations");
      },
    }
  );
};

// Private
const fetchMe = (url, options) => {
  const token = getSessionValue("api_token");

  const optionsWithToken = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    ...options,
  };
  if (token) {
    optionsWithToken.headers["Authorization"] = `Bearer ${token}`;
  }

  return fetch(url, optionsWithToken).then(async (response) => {
    if (response.status === 204) {
      return;
    } else if (response.status < 400) {
      return response.json();
    } else {
      const message = await response.json();

      throw {
        code: response.status,
        message,
      };
    }
  });
};
