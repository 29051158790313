import Container from "@material-ui/core/Container";
import { Button } from "@material-ui/core";

import { Box } from "@material-ui/system";
import { MainMenuToolbar } from "../components/MainMenuToolbar";
import { getReportUrl } from "../api/api";

export const Reports = (props) => {
  const shipId = props.match.params.shipId;

  return (
    <div>
      <MainMenuToolbar title="Reports" />
      <Container>
        <Box sx={{ mt: 4 }}>
          <Button
            onClick={() => {
              window.location.href = getReportUrl(shipId);
            }}
            variant="contained"
            sx={{ mt: 1 }}
          >
            Generate report
          </Button>
        </Box>
      </Container>
    </div>
  );
};
