import React from "react";
import { LabeledText } from "../components/LabeledText";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import dayjs from "dayjs";
import { TabPanel } from "./TabPanel";
import Container from "@material-ui/core/Container";
import { Button } from "@material-ui/core";
import formatDate from "../helpers/formatDate";
import { useHistory } from "react-router";
import { useDeleteOperation } from "../api/api";

export const OperationTabDetails = ({ operation, hidden, shipId }) => {
  const getMooringHours = () => {
    if (!operation || !operation.first_line) return "-";

    const from = dayjs(operation.first_line);
    const till = dayjs(operation.completed || new Date());

    let minutes = till.diff(from, "minutes");
    const hours = Math.floor(minutes / 60);
    minutes = minutes - hours * 60;

    return `${hours}h ${minutes}m`;
  };

  const history = useHistory();
  const onButtonClick = () => {
    history.push(`/ships/${shipId}/operations/${operation.id}/edit`);
  };

  const deleteOperation = useDeleteOperation(shipId, operation.id, {
    onSuccess: () => {
      if (history.length > 0) {
        history.goBack();
      } else {
        history.replace(`/ships/${shipId}/operations/`);
      }
    },
  });

  return (
    <TabPanel hidden={hidden} index={0} dir={"x"}>
      <Box mb={2}>
        <Container maxWidth="sm">
          <Button onClick={onButtonClick} variant="contained" fullWidth>
            Edit operation
          </Button>
        </Container>
      </Box>
      <Box>
        <Box mx={{ textAlign: "center", marginBottom: 8 }}>
          <Typography variant="subtitle2">Mooring hours</Typography>
          <Typography variant="h4">{getMooringHours()}</Typography>
        </Box>
      </Box>
      <LabeledText label="Location">
        {[operation.country, operation.port, operation.terminal]
          .filter((x) => x)
          .join(" / ")}
      </LabeledText>
      <LabeledText label="First line">
        {formatDate(operation.first_line)}
      </LabeledText>
      {/* <LabeledText label="Moored">{formatDate(operation.moored)}</LabeledText> */}
      <LabeledText label="Unmoored">
        {formatDate(operation.completed)}
      </LabeledText>
      <Box mt={16}>
        <Container maxWidth="sm">
          <Button
            onClick={() => {
              if (
                window.confirm("Are you sure you wish to delete this item?")
              ) {
                deleteOperation.mutate();
              }
            }}
            variant="contained"
            color="error"
          >
            Delete operation
          </Button>
        </Container>
      </Box>
    </TabPanel>
  );
};
