export const aftConfigAigran = {
  deck: "M100 10 C50,10 10,20 5,30 v40 C10,80 50,90 100,90",
  direction: "AFT",
  arrangements: [
    {
      name: "Spring",
      color: "#9E2D46",
      point: { x: 75, y: 11 }, //  Point on ship's side
      spread: { x: 5, y: 0 }, //  Spread function
      vector: { x: 60, y: -20 }, //  Direction where all lines go from ship's side
      lineOrder: ["portside", "starboard", "spare"],
    },
    {
      name: "Breast",
      color: "#2D469E",
      point: { x: 50, y: 13 }, //  Point on ship's side
      spread: { x: 2, y: 0 }, //  Spread function
      vector: { x: 0, y: -40 }, //  Direction where all lines go from ship's side
      lineOrder: ["portside", "starboard", "spare"],
    },
    {
      name: "Stern",
      color: "#469E2D",
      point: { x: 20, y: 21 }, //  Point on ship's side
      spread: { x: 3, y: -1 }, //  Spread function
      vector: { x: -70, y: -40 }, //  Direction where all lines go from ship's side
      lineOrder: ["starboard", "portside", "spare"],
    },
  ],
  arrangementsStb: [
    {
      name: "Spring",
      color: "#9E2D46",
      point: { x: 75, y: 89 }, //  Point on ship's side
      spread: { x: 5, y: 0 }, //  Spread function
      vector: { x: 60, y: 20 }, //  Direction where all lines go from ship's side
      lineOrder: ["starboard", "portside", "spare"],
    },
    {
      name: "Breast",
      color: "#2D469E",
      point: { x: 50, y: 87 }, //  Point on ship's side
      spread: { x: 2, y: 0 }, //  Spread function
      vector: { x: 0, y: 40 }, //  Direction where all lines go from ship's side
      lineOrder: ["starboard", "portside", "spare"],
    },
    {
      name: "Stern",
      color: "#469E2D",
      point: { x: 20, y: 79 }, //  Point on ship's side
      spread: { x: 3, y: 1 }, //  Spread function
      vector: { x: -70, y: 40 }, //  Direction where all lines go from ship's side
      lineOrder: ["portside", "starboard", "spare"],
    },
  ],
};
