import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import { OperationCard } from "../components/OperationCard";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import { useGetOperations } from "../api/api";
import { MainMenuToolbar } from "../components/MainMenuToolbar";

export const Operations = (props) => {
  const shipId = props.match.params.shipId;

  const { data: operations = [] } = useGetOperations(shipId);

  return (
    <div>
      <MainMenuToolbar title="Operations" />
      <Container maxWidth="sm">
        {/* TODO: move padding to container (how?) */}
        <Grid container spacing={2} sx={{ mt: 1, pb: 5 }}>
          {operations
            .sort((lhs, rhs) => {
              const lDate = new Date(lhs.first_line).getTime();
              const rDate = new Date(rhs.first_line).getTime();
              return rDate - lDate;
            })
            .map((operation) => {
              return (
                <Grid key={operation.id} item xs={12}>
                  <OperationCard operation={operation} shipId={shipId} />
                </Grid>
              );
            })}
        </Grid>
      </Container>
      <Link to={`/ships/${shipId}/new-mooring/`}>
        <Fab
          color="primary"
          aria-label="add"
          sx={{ position: "fixed", zIndex: 1, bottom: 16, right: 24 }}
        >
          <AddIcon />
        </Fab>
      </Link>
    </div>
  );
};
