import { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import {
  useCreateInspection,
  useEditInspection,
  useGetInspection,
} from "../api/api";
import { useHistory } from "react-router-dom";
import { MainMenuToolbar } from "../components/MainMenuToolbar";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/lab";

export const InspectionNew = ({ match }) => {
  const shipId = match.params.shipId;
  const history = useHistory();

  const [error, setError] = useState();

  const createInspection = useCreateInspection(shipId, {
    onSuccess: () => {
      history.push(`/ships/${shipId}/inspections/`);
    },
    onError: setError,
  });

  return (
    <InspectionPartial
      title="New Inspection"
      buttonTitle="Add"
      shipId={shipId}
      error={error}
      mutation={createInspection}
    />
  );
};

export const InspectionEdit = ({ match }) => {
  const { shipId, inspectionId } = match.params;
  const history = useHistory();

  const [error, setError] = useState();

  const { data: inspection } = useGetInspection(shipId, inspectionId);

  const editInspection = useEditInspection(shipId, inspectionId, {
    onSuccess: () => {
      history.push(`/ships/${shipId}/inspections/`);
    },
    onError: setError,
  });

  return (
    <InspectionPartial
      title="Edit Inspection"
      buttonTitle="Save"
      shipId={match.params.shipId}
      object={inspection}
      error={error}
      mutation={editInspection}
    />
  );
};

const InspectionPartial = ({
  title,
  buttonTitle,
  shipId,
  object,
  error,
  mutation,
}) => {
  const [person, setPerson] = useState(object?.person || "");
  const [notes, setNotes] = useState(object?.notes || "");
  const [date, setDate] = useState(object?.date || dayjs());

  useEffect(() => {
    if (object) {
      setPerson(object.person);
      setNotes(object.notes);
      setDate(object.date);
    }
  }, [object]);

  return (
    <div>
      <MainMenuToolbar
        title={title}
        backLink={`/ships/${shipId}/inspections/`}
      />
      <Container maxWidth="sm">
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <TextField
            id="input-name"
            label="Person"
            margin="normal"
            value={person}
            onChange={(event) => {
              setPerson(event.target.value);
            }}
            variant="standard"
            sx={{ flex: "0 0 48%" }}
          />
          <TextField
            multiline={true}
            id="input-notes"
            label="Notes"
            margin="normal"
            value={notes}
            onChange={(event) => {
              setNotes(event.target.value);
            }}
            variant="standard"
            sx={{ flex: "0 0 48%" }}
          />

          <DateTimePicker
            label="Date&Time picker"
            value={date}
            onChange={(x) => {
              setDate(x);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ flex: "0 0 48%", mt: 2 }}
                variant="standard"
              />
            )}
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          {error?.message && (
            <Typography variant="body1" color="error">
              {`${JSON.stringify(error?.message)}`}
            </Typography>
          )}
          <Button
            disabled={!person || !date}
            onClick={() => {
              mutation.mutate({
                person,
                notes,
                date,
              });
            }}
            variant="contained"
            sx={{ mt: 4 }}
          >
            {buttonTitle}
          </Button>
        </Box>
      </Container>
    </div>
  );
};
