import { useState } from "react";
import Container from "@material-ui/core/Container";
import { Box, TextField, Button, Typography } from "@material-ui/core";
import { MainMenuToolbar } from "../components/MainMenuToolbar";
import { useLogin } from "../api/api";
import { useHistory } from "react-router";

export const Login = ({ setToken }) => {
  const [ship, setShip] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState();

  const history = useHistory();

  const mutation = useLogin({
    onSuccess: (response) => {
      setToken(response.authentication_token);
      history.replace("/");
    },
    onError: setError,
  });

  return (
    <div>
      <MainMenuToolbar title="Login" />
      <Container maxWidth="sm">
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <TextField
            id="input-ship-name"
            label="Ship"
            margin="normal"
            value={ship}
            onChange={(event) => {
              setShip(event.target.value);
            }}
            variant="standard"
            sx={{ flex: "0 0 48%" }}
          />
          <TextField
            type="password"
            id="input-password"
            label="Password"
            margin="normal"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            variant="standard"
            sx={{ flex: "0 0 48%" }}
          />
        </Box>
        <Box sx={{ mt: 1 }}>
          {error?.message && (
            <Typography variant="body1" color="error">
              {`${error?.message?.error}`}
            </Typography>
          )}
          <Button
            disabled={!ship || !password}
            onClick={() => {
              mutation.mutate({
                ship,
                password,
              });
            }}
            variant="contained"
            sx={{ mt: 4 }}
          >
            Login
          </Button>
        </Box>
      </Container>
    </div>
  );
};
