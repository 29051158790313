export const fwdConfigAigran = {
  deck: "M0 10 Q50,10 95,50 Q50,90 0,90",
  direction: "FWD",
  arrangements: [
    {
      name: "Spring",
      color: "#9E2D46",
      point: { x: 20, y: 12 }, //  Point on ship's side
      spread: { x: 5, y: 0.5 }, //  Spread function
      vector: { x: -40, y: -20 }, //  Direction where all lines go from ship's side
      lineOrder: ["starboard", "portside", "spare"],
    },
    {
      name: "Head",
      color: "#469E2D",
      point: { x: 75, y: 34 }, //  Point on ship's side
      spread: { x: 3, y: 2 }, //  Spread function
      vector: { x: 100, y: -40 }, //  Direction where all lines go from ship's side
      lineOrder: ["portside", "starboard", "spare"],
    },
    {
      name: "Breast",
      color: "#2D469E",
      point: { x: 50, y: 20 }, //  Point on ship's side
      spread: { x: 2, y: 0 }, //  Spread function
      vector: { x: 0, y: -20 }, //  Direction where all lines go from ship's side
      lineOrder: ["portside", "starboard", "spare"],
    },
  ],
  arrangementsStb: [
    {
      name: "Spring",
      color: "#9E2D46",
      point: { x: 20, y: 88 }, //  Point on ship's side
      spread: { x: 5, y: -0.5 }, //  Spread function
      vector: { x: -40, y: 20 }, //  Direction where all lines go from ship's side
      lineOrder: ["portside", "starboard", "spare"],
    },
    {
      name: "Head",
      color: "#469E2D",
      point: { x: 75, y: 66 }, //  Point on ship's side
      spread: { x: 3, y: -2 }, //  Spread function
      vector: { x: 100, y: 40 }, //  Direction where all lines go from ship's side
      lineOrder: ["starboard", "portside", "spare"],
    },
    {
      name: "Breast",
      color: "#2D469E",
      point: { x: 50, y: 80 }, //  Point on ship's side
      spread: { x: 2, y: 0 }, //  Spread function
      vector: { x: 0, y: 20 }, //  Direction where all lines go from ship's side
      lineOrder: ["starboard", "portside", "spare"],
    },
  ],
};
