import { useHistory } from "react-router";
import { useState } from "react";
import { LineDetailsPartial } from "./LineDetailsPartial";
import { useCreateMooringLine } from "../api/api";

export const LineDetailsNew = (props) => {
  const { shipId } = props.match.params;

  const history = useHistory();

  const [editedCable, setEditedCable] = useState({});
  const [error, setError] = useState();

  const createCable = useCreateMooringLine(shipId, {
    onSuccess: () => {
      if (history.length > 0) {
        history.goBack();
      } else {
        history.push(`/ships/${shipId}/lines/`);
      }
    },
    onError: setError,
  });

  return (
    <LineDetailsPartial
      title="New line"
      backLink={`/ships/${shipId}/lines/`}
      submitButtonTitle="Create"
      editedCable={editedCable}
      error={error}
      setEditedCable={setEditedCable}
      onSubmitClick={() => {
        createCable.mutate(editedCable);
      }}
    />
  );
};
