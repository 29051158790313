import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { useGetShips } from "./../api/api";
import { OperationCard } from "../components/OperationCard";
import { Link } from "react-router-dom";
import { MainMenuToolbar } from "../components/MainMenuToolbar";

export const Dashboard = () => {
  const { data: ships = [] } = useGetShips();

  return (
    <div>
      <MainMenuToolbar title="Dashboard" />
      <Grid container spacing={2} sx={{ mt: 1, pb: 5 }}>
        {ships.map((ship) => {
          return (
            <Container maxWidth="sm">
              <Grid key={ship.id} spacing={2} sx={{ mt: 1, pb: 5 }}>
                <Card>
                  <CardContent>
                    <Link to={`/ships/${ship.id}/operations`}>
                      <Typography variant="h4" sx={{ mb: 2 }}>
                        {ship.title}
                      </Typography>
                    </Link>
                    {ship.operation ? (
                      <OperationCard
                        operation={ship.operation}
                        shipId={ship.id}
                      />
                    ) : (
                      <div />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Container>
          );
        })}
      </Grid>
    </div>
  );
};
