import * as React from "react";
import { Menu } from "@material-ui/core";
import { useAddCable } from "../../api/api";
import {
  Circle,
  getLineStartingPoint,
  MenuItemsArrangements,
  MenuItemsCables,
  useGetGroupedEquipment,
} from "./helpers";

const DeckPlan = ({
  config,
  canEdit,
  shipId,
  operationId,
  equipment,
  side,
}) => {
  const groupedEquipment = useGetGroupedEquipment(shipId, config.direction);

  const stationsAndLocations = groupedEquipment.map((x) => {
    const first = x[0];
    return {
      ...first.position_on_deck,
      stations: x,
    };
  });

  const arrangement =
    side === "PORT" ? config.arrangements : config.arrangementsStb;

  const cables = arrangement.flatMap((arrangement) => {
    return equipment
      .filter((x) => x.arrangement === arrangement.name)
      .map((cable) => {
        const locationName =
          cable.winch.type === "spare" ? "spare" : cable.winch.station_name;
        return {
          ...cable,
          priority: arrangement.lineOrder.indexOf(locationName),
        };
      })
      .sort((lhs, rhs) => lhs.priority - rhs.priority)
      .map((equipment, index) => {
        const stroke = `L${
          arrangement.point.x + index * arrangement.spread.x
        },${arrangement.point.y + index * arrangement.spread.y} l${
          arrangement.vector.x
        },${arrangement.vector.y}`;

        return (
          <path
            fill="none"
            stroke={arrangement.color}
            strokeWidth="1"
            d={`M${getLineStartingPoint(equipment)} ${stroke}`}
          />
        );
      });
  });

  const arrangementNames = config.arrangements.map((x) => x.name);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [stations, setStations] = React.useState([]);
  const [cable, setCable] = React.useState();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const addCable = useAddCable(shipId, operationId);

  const portsideLine = "M0 1 100 1";
  const starboardLine = "M0 99 100 99";

  return (
    <div>
      <svg viewBox="0 0 100 100" style={{ width: "100%" }}>
        <path
          fill="#CCCCCC"
          stroke="gray"
          strokeWidth="2"
          d={side === "PORT" ? portsideLine : starboardLine}
        />
        <path fill="#CCCCCC" stroke="gray" strokeWidth="1" d={config.deck} />
        {cables}
        {stationsAndLocations.map((sal) => {
          return (
            <Circle
              x={sal.x}
              y={sal.y}
              onClick={(event) => {
                if (canEdit) {
                  setStations(sal.stations);
                  setAnchorEl(event.currentTarget);
                }
              }}
            />
          );
        })}
      </svg>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {cable ? (
          <MenuItemsArrangements
            arrangements={arrangementNames}
            onArrangementSelect={(arrangement) => {
              setAnchorEl(null);
              setCable();
              addCable.mutate({
                arrangement: arrangement,
                cable_id: cable.id,
              });
            }}
          />
        ) : (
          <MenuItemsCables
            disabledCableIds={equipment.map((x) => x.cable.id)}
            stations={stations}
            onCableSelect={(cable) => {
              setCable(cable);
            }}
          />
        )}
      </Menu>
    </div>
  );
};

export default DeckPlan;
