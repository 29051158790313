import Container from "@material-ui/core/Container";
import { Box } from "@material-ui/system";
import { useGetEquipment } from "../api/api";
import { LabeledText } from "../components/LabeledText";
import { MainMenuToolbar } from "../components/MainMenuToolbar";
import formatStaticEquipment from "../helpers/formatStaticEquipment";
import { useGetShip } from "../api/api";

export const Ship = (props) => {
  const shipId = props.match.params.shipId;

  const ship = useGetShip(shipId);
  const { data: shipsEquipment = [] } = useGetEquipment(shipId);

  return (
    <div>
      <MainMenuToolbar title="Reports" />
      <Container maxWidth="sm">
        <LabeledText label="Name">{ship.title}</LabeledText>
        <LabeledText label="Ships standard LDBF">
          {ship.ldbf?.min} - {ship.ldbf?.max} tons
        </LabeledText>
        <LabeledText label="Available rope locations">
          {shipsEquipment.map((equipment) => {
            return (
              <Box mb={2}>
                {formatStaticEquipment(equipment)}
                <br />
                contains lines:{" "}
                {equipment.cables.map((cable) => cable.tag).join(", ")}
              </Box>
            );
          })}
        </LabeledText>
      </Container>
    </div>
  );
};
