import { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import {
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { MainMenuToolbar } from "../components/MainMenuToolbar";
import { DateTimePicker } from "@mui/lab";
import dayjs from "dayjs";
import {
  useGetMooringLine,
  useGetEquipment,
  useCreateMaintenance,
} from "../api/api";
import { useHistory } from "react-router";
import formatStaticEquipment from "../helpers/formatStaticEquipment";

export const LineOperationNew = ({ match }) => {
  const { shipId, lineId } = match.params;

  const { data: line } = useGetMooringLine(shipId, lineId);
  const { data: equipment = [] } = useGetEquipment(shipId);

  const [type, setType] = useState();
  const [person, setPerson] = useState();
  const [date, setDate] = useState(dayjs());
  const [notes, setNotes] = useState();

  const types = [
    "Maintenance",
    "End-to-end change",
    "Location change",
    "Retirement",
    "Disposal",
    "other",
  ];

  const [editedCable, setEditedCable] = useState({
    remaining: 0,
    length: 0,
    diameter: 0,
    LDBF: 0,
    static_equipment: {},
  });

  useEffect(() => {
    if (line && !editedCable.id) {
      setEditedCable(line);
    }
  }, [line]);

  const remainingAdjustment = Math.round(
    (editedCable.remaining - (line?.remaining ?? 0)) / 3600
  );
  const lengthAdjustment = editedCable.length - (line?.length ?? 0);
  const diameterAdjustment = editedCable.diameter - (line?.diameter ?? 0);
  const ldbfAdjustment = editedCable.LDBF - (line?.LDBF ?? 0);

  const history = useHistory();

  const [error, setError] = useState();

  const mutation = useCreateMaintenance(shipId, lineId, {
    onSuccess: () => {
      if (history.length > 1) {
        history.goBack();
      } else {
        history.replace(`/ships/${shipId}/lines/${lineId}`);
      }
    },
    onError: setError,
  });

  const saveOperation = () => {
    mutation.mutate({
      type,
      person,
      date,
      notes,
      old_location: line.static_equipment?.id,
      new_location: editedCable.static_equipment?.id,
      old_remaining_lifespan: line.remaining,
      new_remaining_lifespan: editedCable.remaining,
      old_length: line.length,
      new_length: editedCable.length,
      old_diameter: line.diameter,
      new_diameter: editedCable.diameter,
      old_LDBF: line.LDBF,
      new_LDBF: editedCable.LDBF,
    });
  };

  return (
    <div>
      <MainMenuToolbar
        title={`Add new Operation to #${line?.tag}`}
        backLink={`/ships/${shipId}/lines/${lineId}`}
      />
      <Container maxWidth="sm">
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <TextField
            error={error?.message?.["type"]}
            helperText={error?.message?.["type"]}
            select
            id="input-type"
            label="Type *"
            margin="normal"
            value={type}
            onChange={(event) => {
              setType(event.target.value);
            }}
            variant="standard"
            sx={{ flex: "0 0 48%" }}
          >
            {types.map((type) => {
              return (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            error={error?.message?.["person"]}
            helperText={error?.message?.["person"]}
            id="input-person"
            label="Person *"
            margin="normal"
            value={person}
            onChange={(event) => {
              setPerson(event.target.value);
            }}
            variant="standard"
            sx={{ flex: "0 0 48%" }}
          />
          <DateTimePicker
            label="Date *"
            value={date}
            onChange={(x) => {
              setDate(x);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ flex: "0 0 48%", mt: 2 }}
                variant="standard"
              />
            )}
          />
          <TextField
            id="input-notes"
            label="Notes"
            margin="normal"
            value={notes}
            onChange={(event) => {
              setNotes(event.target.value);
            }}
            variant="standard"
            multiline={true}
            sx={{ flex: "0 0 48%" }}
          />

          {type === "Location change" || type === "Retirement" ? (
            <div>
              <Typography variant="h4" sx={{ mt: 4 }}>
                Location
              </Typography>
              <Typography variant="subtitle">
                New location after the operation
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <TextField
                  id="input-select-line"
                  label="Location"
                  margin="normal"
                  select
                  value={editedCable.static_equipment?.id || "None"}
                  onChange={(event) => {
                    const newId = event.target.value;
                    const newLocation = equipment.find((x) => x.id === newId);
                    setEditedCable({
                      ...editedCable,
                      static_equipment: newLocation,
                    });
                  }}
                  variant="standard"
                >
                  <MenuItem key="None" value="None">
                    none
                  </MenuItem>

                  {equipment.map((x) => (
                    <MenuItem key={x.id} value={x.id}>
                      {formatStaticEquipment(x)}
                    </MenuItem>
                  ))}
                </TextField>
                {line?.static_equipment &&
                  line.static_equipment?.id !==
                    editedCable.static_equipment?.id && (
                    <Typography variant="subtitle" color="success.main">
                      Previous location:{" "}
                      {formatStaticEquipment(line.static_equipment)}
                    </Typography>
                  )}
              </Box>
            </div>
          ) : (
            <div />
          )}

          {type === "Maintenance" ? (
            <div>
              {" "}
              <Typography variant="h4" sx={{ mt: 4 }}>
                Mooring line
              </Typography>
              <Typography variant="subtitle">
                Adjusted parameters after operation
              </Typography>
              <LineAdjustmentInput
                label="Remaining lifespan (h)"
                value={editedCable.remaining / 3600}
                onChange={(event) => {
                  setEditedCable({
                    ...editedCable,
                    remaining: event.target.value * 3600,
                  });
                }}
                adjustment={remainingAdjustment}
              />
              <LineAdjustmentInput
                label="Length (m)"
                value={editedCable?.length || ""}
                onChange={(event) => {
                  setEditedCable({
                    ...editedCable,
                    length: event.target.value,
                  });
                }}
                adjustment={lengthAdjustment}
              />
              <LineAdjustmentInput
                label="Diameter (mm)"
                value={editedCable?.diameter || ""}
                onChange={(event) => {
                  setEditedCable({
                    ...editedCable,
                    diameter: event.target.value,
                  });
                }}
                adjustment={diameterAdjustment}
              />
              <LineAdjustmentInput
                label="LDBF (tons)"
                value={editedCable?.LDBF || ""}
                onChange={(event) => {
                  setEditedCable({
                    ...editedCable,
                    LDBF: event.target.value,
                  });
                }}
                adjustment={ldbfAdjustment}
              />
            </div>
          ) : (
            <div />
          )}
        </Box>
        <Button
          disabled={!type || !person || !date}
          onClick={() => {
            if (type === "Retirement") {
              if (
                window.confirm("Are you sure you wish to retire this item?")
              ) {
                saveOperation();
              }
            } else {
              saveOperation();
            }
          }}
          variant="contained"
          sx={{ mt: 1, mb: 4 }}
        >
          Add
        </Button>
      </Container>
    </div>
  );
};

const LineAdjustmentInput = ({ label, value, onChange, adjustment }) => {
  const formatted = Number.isInteger(adjustment)
    ? adjustment
    : adjustment?.toFixed(1);
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <TextField
        label={label}
        margin="normal"
        value={value}
        type="number"
        onChange={onChange}
        variant="standard"
        sx={{ flex: 1 }}
      />
      <Typography
        variant="subtitle"
        sx={{ mt: 4, ml: 8 }}
        color={adjustment < 0 ? "error.main" : "success.main"}
      >
        {formatted}
      </Typography>
    </Box>
  );
};
